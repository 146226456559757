import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import InvoiceTable from "../Table/InvoiceTable";
import ExceptionInvoiceTable from "../Table/ExceptionInvoiceTable";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import InvoiceFilter from "./Modal/InvoiceFilter";
import ExceptionListFilter from "./Modal/ExceptionListFilter";
import { debounce, initial } from "underscore";
import {
  GET_INVOICE,
  GET_INVOICE_LIST,
  GET_INVOICE_EXCEPTION_LIST,
  GET_USER_BY_ID,
} from "../comman/url";
import API from "../constants/API";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
  getIsExceptionRole,
} from "../comman/function";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import { FormControl, MenuItem, Select } from "@mui/material";
import Uploadinvoice from "./Modal/Uploadinvoice";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PagenationPages from "./PagenationPages";
import { useLocation, useNavigate } from "react-router-dom";
import UploadInvoiceNew from "./Modal/UploadInvoiceNew";
import { useSelector } from "react-redux";
import RejectedInvoiceList from "../Table/RejectedInvoiceList";
import RejectedInvFilter from "./Modal/RejectedInvFilter";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
  tablerow: {
    background: "black !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 10px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  exceptionbutton: {
    margin: "0px 10px !important",
    backgroundColor: "#FF751930 !important",
    color: "#FF7519 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 10px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 10px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  tabtaneldiv: {
    padding: "0px 0px 24px 0px !important",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    color: "#14142A !important",
    padding: "4px !important",
  },
  refreshbtn: {
    color: "#EC7C37 !important",
    border: "1px solid #EC7C37 !important",
    minWidth: "unset !important",
    padding: "4px 6px !important",
  },

  downbn: {
    backgroundColor: "#ec7c37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    border: "1px solid #ec7c37 !important",
    "&:hover": {
      color: "#ec7c37 !important",
      fontSize: "12px !important",
      border: "1px solid #ec7c37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
}));

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: 600,
    marginRight: theme.spacing(1),
    // color: "rgba(0, 0, 0, 0.85)",
    color: "#A0A3BD",
    fontSize: "18px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
const initialdata = {
  offset: 0,
  invoiceAmount: "",
  companyName: "",
  supplierCompanyName: "",
  invoiceNumber: "",
  count: 10,
  email: "",
  status: "",
  name: "",
  receiverEmail: "",
  fromAmount: "",
  invCreatedDate: "",
  toAmount: "",
};
const Exceptioninitialdata = {
  offset: 0,
  invoiceAmount: "",
  companyName: "",
  supplierCompanyName: "",
  invoiceNumber: "",
  count: 10,
  email: "",
  status: "",
  name: "",
};
const RejectedInvInitialData = {
  offset: 0,
  count: 10,
  status: "rejected",
  invoiceNumber: "",
};
export default function Invoice(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = props;
  const locaction = useLocation();
  const { state = {} } = locaction;
  const { status = "", date = "", tab = "", invCreatedDate = "" } = state || {};
  const navigaton = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [exceptionData, setexceptionData] = useState([]);
  const [totalrecords, setTotalRecords] = useState(0);
  const [totalrecordsStatus, setTotalRecordsStatus] = useState(0);
  const [totalExcepStatus, settotalExcepStatus] = useState(0);
  const [exceptiontotalrecords, setexceptionTotalRecords] = useState(0);

  const [exceptionParams, setexceptionParams] = useState(Exceptioninitialdata);
  const [dataparams, setdataParams] = useState(initialdata);
  const [value, setValue] = React.useState(tab ? tab : "1");
  const [countSet, setCountSet] = useState({});
  const [countException, setStatusCountException] = useState({});
  const [exceptionCount, setExceptionCount] = useState(0);
  const [rejectedToggle, setRejectedToggle] = useState(false);
  const [approvedToggle, setApprovedToggle] = useState(false);
  const [exceptionToggle, setexceptionToggle] = useState(false);
  const [othersToggle, setOthersToggle] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(6);
  const [hasExceptionRole, sethasExceptionRole] = useState(0);
  const styleclasses = useStyles();
  const [invoiceModelFlag, setInvoiceModelFlag] = useState(false);

  const [rejectedData, setrejectedData] = useState([]);
  const [rejlistcount, setrejlistcount] = useState();
  const [rejectedCount, setrejectedCount] = useState();
  const [rejlistloading, setrejlistloading] = useState(false);
  const [rejectedinvparams, setrejectedinvparams] = useState(
    RejectedInvInitialData
  );
  const TeamReducer = useSelector((s) => s.TeamReducer);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const tabHandler = (event, newValue) => {
    setValue(newValue);
  };
  // console.log(tab,"ff")
  const calculateOthersPercentage = (countSet, totalOfAllStatus) => {
    let reqStatus = [
      "Initializing",
      "string",
      "Rejected",
      "Request Information",
      "Invoice Rejection",
      "Specialist Exception",
      "Reprocessing",
      "Supplier Maintenance",
      "Information Requested",
      "Reject Invoice",
    ];
    // console.log(parseInt());
    let totalOfReqStatus = reqStatus.reduce(
      (tot, e) => tot + (countSet[e] || 0),
      0
    );

    return totalOfReqStatus;
  };

  const delaySearch = useRef(
    debounce((dataparams) => getInvoiceList(dataparams), 500)
  ).current;

  useEffect(() => {
    delaySearch({
      ...dataparams,
    });
  }, [dataparams]);
  useEffect(() => {
    if (status !== "ExceptionStatus") {
      setdataParams((va) => ({
        ...va,
        status: status,
        invCreatedDate: invCreatedDate,
      }));

      navigaton({ ...locaction }, { state: {} });
      // return () => {
      //   console.log("hello");

      //   navigaton({ ...locaction, state: {} });
      // };
    }
  }, []);
  const delaySearchException = useRef(
    debounce((exceptionParams) => getExceptionInvoiceList(exceptionParams), 500)
  ).current;

  useEffect(() => {
    delaySearchException(exceptionParams);
  }, [exceptionParams]);

  const openFilterHandle = () => {
    setInvoiceModelFlag(true);
  };
  const closeFilterHandler = () => {
    setInvoiceModelFlag(false);
  };
  const getExceptionInvoiceList = async (filter) => {
    const config = {
      method: "GET",
      url: GET_INVOICE_EXCEPTION_LIST,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            userId: getRole()=== "Admin" ? "": getIsExceptionRole() == 1 ? getUserId() : "",

            ...filter,
            name: filter.name?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data, count, statusCount, totalCount } = JSON.parse(l);
      if (status === "Success") {
        console.log("exception", data);
        if (statusCount) {
          const obj = Object.fromEntries(
            statusCount.map((item) => [item.status, item.invoiceCount])
          );
          console.log(obj);
          setExceptionCount(count);
          setStatusCountException(obj);
          settotalExcepStatus(totalCount);
        }
        setexceptionData(
          data.map((el, index) => {
            return {
              ...el,
              invoiceNumber: el.invoiceNumber || "N/A",
              invoiceAmount: el.invoiceAmount || 0,
            };
          })
        );
        setexceptionTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  const getUser = async () => {
    const config = {
      method: "GET",
      url: GET_USER_BY_ID,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      console.log("rty");
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        const { tblExceptionHandlerRoleId } = data[0];
        const roleIsException = JSON.parse(
          tblExceptionHandlerRoleId.replace(/'/g, '"')
        );
        console.log("rty", roleIsException);
        // localStorage.setItem("isException", roleIsException);
        if (roleIsException.length != 0) {
          sethasExceptionRole(1);
        }
      }
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  const getInvoiceList = async (filter) => {
    const config = {
      method: "GET",
      url: GET_INVOICE_LIST,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: !["Supplier", "Sub Supplier"].includes(getRole())
              ? getTeamID()
              : 0,
            senderEmail:
              getRole() === "Supplier" || getRole() === "Sub Supplier"
                ? getSenderEmail()
                : "",
            ...filter,
            name: filter.name?.split("'").join("\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data, count, statusCount, totalCount } = JSON.parse(l);

      if (status === "Success") {
        setData(
          data.map((el, index) => {
            return {
              ...el,
              invoiceNumber: el.invoiceNumber || "N/A",
              invoiceAmount: el.invoiceAmount || 0,
              flag: el.isResubmited == 1 ? true : false,
            };
          })
        );

        setTotalRecords(count);
        setTotalRecordsStatus(totalCount);
        setLoading(false);
        console.log("statuscount", statusCount);
        if (statusCount) {
          const obj = Object.fromEntries(
            statusCount.map((item) => [item.status, item.invoiceCount])
          );
          console.log(obj);
          setCountSet(obj);
        }
      }
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const getRejectedList = async (debounceparams) => {
    const config = {
      method: "GET",
      url: GET_INVOICE_LIST,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            ...debounceparams,
            teamId: getRole() !== "Supplier" ? getTeamID() : 0,
            senderEmail: getRole() === "Supplier" ? getSenderEmail() : "",
            status: "Rejected",
            name: debounceparams.name?.split("'").join("\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      setrejlistloading(true);
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data, count, statusCount, totalCount } = JSON.parse(l);

      if (status === "Success") {
        setrejectedData(
          data.map((el, index) => {
            return {
              ...el,
              invoiceNumber: el.invoiceNumber || "N/A",
              invoiceAmount: el.invoiceAmount || 0,
              flag: el.isResubmited == 1 ? true : false,
            };
          })
        );
        setrejlistcount(count);
        setrejlistloading(false);
      }
    } catch (error) {
      console.log(error.response);
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setrejlistloading(false);
    }
  };
  const delayRejectedinv = useRef(
    debounce((rejectedinvparams) => getRejectedList(rejectedinvparams), 500)
  ).current;

  useEffect(() => {
    delayRejectedinv(rejectedinvparams);
  }, [rejectedinvparams]);

  // useEffect(() => {
  //   getInvoiceList();
  // }, []);

  const searchFilter = (e) => {
    e.persist();

    setdataParams((value) => ({
      ...value,
      invoiceNumber: e?.target?.value || "",
      offset: 0,
    }));
  };
  const searchFilter1 = (e) => {
    e.persist();

    setexceptionParams((value) => ({
      ...value,
      invoiceNumber: e?.target?.value || "",
      offset: 0,
    }));
  };
  const searchFilter2 = (e) => {
    e.persist();

    setrejectedinvparams((value) => ({
      ...value,
      invoiceNumber: e?.target?.value || "",
      offset: 0,
    }));
  };
  const filterHandler = (filterData) => {
    setdataParams((value) => ({
      ...value,
      ...filterData,
      offset: 0,
    }));
  };
  const filterHandler1 = (filterDataEx) => {
    setexceptionParams((value) => ({
      ...value,
      ...filterDataEx,
      offset: 0,
    }));
    // console.log("fromexceptionfilter");
  };
  const filterHandler2 = (filterDatarej) => {
    setrejectedinvparams((value) => ({
      ...value,
      ...filterDatarej,
      offset: 0,
    }));
    // console.log("fromexceptionfilter");
  };

  const nextPage = () => {
    value == 1
      ? setdataParams((va) => ({ ...va, offset: va.offset + 1 }))
      : value == 2
      ? setexceptionParams((val) => ({ ...val, offset: val.offset + 1 }))
      : setrejectedinvparams((val) => ({ ...val, offset: val.offset + 1 }));
  };

  const previorsPage = () => {
    value == 1
      ? setdataParams((va) => ({ ...va, offset: va.offset - 1 }))
      : value == 2
      ? setexceptionParams((val) => ({ ...val, offset: val.offset - 1 }))
      : setrejectedinvparams((val) => ({ ...val, offset: val.offset - 1 }));
  };
  const rowHandle = (ve) => {
    value == 1
      ? setdataParams((v) => ({ ...v, count: ve.target.value, offset: 0 }))
      : value == 2
      ? setexceptionParams((va) => ({
          ...va,
          count: ve.target.value,
          offset: 0,
        }))
      : setrejectedinvparams((va) => ({
          ...va,
          count: ve.target.value,
          offset: 0,
        }));
  };

  const Refresh = () => {
    value == 1
      ? setdataParams((refreshdata) => {
          return { ...refreshdata };
        })
      : value == 2
      ? setexceptionParams((refreshxceptiondata) => {
          return { ...refreshxceptiondata };
        })
      : setrejectedinvparams((refreshrejdata) => {
          return { ...refreshrejdata };
        });
  };
  // console.log(dataparams)
  const [inviteSuppliermodal, setInviteSuppliermodal] = React.useState(false);
  const createinvoiceopen = () => setInviteSuppliermodal((v) => !v);
  const successCall = () => {
    delaySearch(dataparams);
  };
  console.log("data", Data);
  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" sx={{ fontWeight: 500, fontSize: "34px" }}>
            Invoices
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} sx={{ paddingTop: "0px !important" }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            {/* <Tooltip
              title="Refresh"
              placement="top"
              arrow
              classes={{
                arrow: styleclasses.arrow,
                tooltip: styleclasses.tooltip,
              }}
            >
              <Button className={styleclasses.refreshbtn} onClick={Refresh}>
                <RefreshRoundedIcon />{" "}
              </Button>
            </Tooltip>

            <Button
              onClick={createinvoiceopen}
              className={styleclasses.downbn}
              variant="contained"
            >
              {getRole() == "Supplier" ? "Submit Invoice" : "New Invoice"}
            </Button> */}

            {/* <Button
              sx={{ backgroundColor: "#EC7C37", boxShadow: "none" }}
              variant="contained"
              endIcon={<ArrowDropDownIcon />}
            >
              Download
            </Button> */}
            <img src={TeamReducer.companyLogo} className="companyLogo"></img>
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} sx={{ display: "flex", mt: 2 }}>
        <Grid item xs={12} md={6} lg={6} sx={{ display: "flex" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: "none" }}>
              {getRole() == "Admin" || getIsExceptionRole() == 1 ? (
                <AntTabs
                  sx={{ borderBottom: "none" }}
                  value={value}
                  onChange={tabHandler}
                  aria-label="ant example"
                >
                  <AntTab
                    label="Invoices"
                    value="1"
                    style={{ fontWeight: 500 }}
                  />

                  <AntTab
                    label="Exceptions"
                    value="2"
                    style={{ fontWeight: 500 }}
                  />
                  <AntTab
                    label="Rejected Invoices"
                    value="3"
                    style={{ fontWeight: 500 }}
                  />
                </AntTabs>
              ) : (
                " "
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid sx={{ pt: 1, mt: 1, mb: 1 }} container spacing={2}>
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
          <Grid item xs={12} md={3} lg={3} sx={{ display: "flex" }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "-webkit-fill-available",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "20px",
              }}
            >
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              {value == 1 && (
                <InputBase
                  sx={{ flex: 1 }}
                  placeholder="Search Invoice Number"
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={searchFilter}
                  value={dataparams.invoiceNumber}
                />
              )}
              {value == 2 && (
                <InputBase
                  sx={{ flex: 1 }}
                  placeholder="Search Invoice Number"
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={searchFilter1}
                  value={exceptionParams.invoiceNumber}
                />
              )}
              {value == 3 && (
                <InputBase
                  sx={{ flex: 1 }}
                  placeholder="Search Invoice Number"
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={searchFilter2}
                  value={rejectedinvparams.invoiceNumber}
                />
              )}

              {/* <Button
                className={styleclasses.advancedbutton}
                endIcon={<ArrowDropDownRoundedIcon />}
              >
                Advanced
              </Button> */}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: "flex", mt: 2, mb: 1 }}>
            {value == 1 && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "left" }}
              >
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  Pending:
                  <Button
                    onClick={() => setApprovedToggle(!approvedToggle)}
                    className={styleclasses.pendingbutton}
                  >
                    <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                      {countSet && countSet.Pending
                        ? approvedToggle === false
                          ? `${(
                              (countSet.Pending / totalrecordsStatus) *
                              100
                            ).toFixed()}%`
                          : countSet.Pending
                        : 0}
                    </Typography>
                  </Button>
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  Approved:
                  {/* <Tooltip
                  //  title="Approved"
                  placement="top"
                  arrow
                  classes={{
                    arrow: styleclasses.arrow,
                    tooltip: styleclasses.tooltip,
                  }}
                > */}
                  <Button
                    onClick={() => setApprovedToggle(!approvedToggle)}
                    className={styleclasses.approvedbutton}
                  >
                    <Typography sx={{ fontWeight: 600, fontSize: 15 }}>
                      {countSet && countSet.Approved
                        ? approvedToggle === false
                          ? `${(
                              (countSet.Approved / totalrecordsStatus) *
                              100
                            ).toFixed()}%`
                          : countSet.Approved
                        : 0}
                    </Typography>
                  </Button>
                  {/* </Tooltip> */}
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  Auto Approved:
                  {/* <Tooltip
                //  title="Auto Approved"
                  placement="top"
                  arrow
                  classes={{
                    arrow: styleclasses.arrow,
                    tooltip: styleclasses.tooltip,
                  }}
                > */}
                  <Button className={styleclasses.autoapprovedbutton}>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 15, color: "#4A9EF1" }}
                    >
                      0
                    </Typography>
                  </Button>
                  {/* </Tooltip> */}
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  Others:
                  <Button
                    onClick={() => setApprovedToggle(!approvedToggle)}
                    className={styleclasses.autoapprovedbutton}
                  >
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 15, color: "#4A9EF1" }}
                    >
                      {countSet && calculateOthersPercentage(countSet)
                        ? approvedToggle === false
                          ? calculateOthersPercentage(countSet)
                            ? `${(
                                (calculateOthersPercentage(countSet) /
                                  totalrecordsStatus) *
                                100
                              ).toFixed()}%`
                            : 0
                          : calculateOthersPercentage(countSet)
                        : 0}
                      {/* Initializing,string,Rejected,Request Information,
                        Invoice Rejection,Specialist Exception,Reprocessing,Supplier Maintenance,
                        Information Requested,Reject Invoice*/}
                    </Typography>
                  </Button>
                  {/* </Tooltip> */}
                </Typography>
              </Grid>
            )}
            {value == 2 && (
              <>
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  RI:
                  <Button
                    onClick={() => setexceptionToggle(!exceptionToggle)}
                    className={styleclasses.exceptionbutton}
                  >
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 15, color: "#FF7519" }}
                    >
                      {countException && countException["Request Information"]
                        ? exceptionToggle === false
                          ? `${(
                              (countException["Request Information"] /
                                totalExcepStatus) *
                              100
                            ).toFixed()}%`
                          : countException["Request Information"]
                        : 0}

                      {/* {countException && countException["Request Information"]} */}
                    </Typography>
                  </Button>
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  SM:
                  <Button
                    onClick={() => setexceptionToggle(!exceptionToggle)}
                    className={styleclasses.exceptionbutton}
                  >
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 15, color: "#FF7519" }}
                    >
                      {countException && countException["Supplier Maintenance"]
                        ? exceptionToggle === false
                          ? `${(
                              (countException["Supplier Maintenance"] /
                                totalExcepStatus) *
                              100
                            ).toFixed()}%`
                          : countException["Supplier Maintenance"]
                        : 0}
                    </Typography>
                  </Button>
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  SE:
                  <Button
                    onClick={() => setexceptionToggle(!exceptionToggle)}
                    className={styleclasses.exceptionbutton}
                  >
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 15, color: "#FF7519" }}
                    >
                      {countException && countException["Specialist Exception"]
                        ? exceptionToggle === false
                          ? `${(
                              (countException["Specialist Exception"] /
                                totalExcepStatus) *
                              100
                            ).toFixed()}%`
                          : countException["Specialist Exception"]
                        : 0}
                    </Typography>
                  </Button>
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontSize: "15px", fontWeight: 700 }}
                >
                  RJI:
                  <Button
                    onClick={() => setexceptionToggle(!exceptionToggle)}
                    className={styleclasses.exceptionbutton}
                  >
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 15, color: "#FF7519" }}
                    >
                      {countException && countException["Reject Invoice"]
                        ? exceptionToggle === false
                          ? `${(
                              (countException["Reject Invoice"] /
                                totalExcepStatus) *
                              100
                            ).toFixed()}%`
                          : countException["Reject Invoice"]
                        : 0}
                    </Typography>
                  </Button>
                </Typography>
              </>
            )}
            {value == 3 && (
              <Typography
                align="left"
                sx={{ fontSize: "15px", fontWeight: 700 }}
              >
                Rejected:
                <Button
                  onClick={() => setRejectedToggle(!rejectedToggle)}
                  className={styleclasses.exceptionbutton}
                >
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 15, color: "#FF7519" }}
                  >
                    {countSet && countSet.Rejected
                      ? rejectedToggle === false
                        ? `${(
                            (countSet.Rejected / totalrecordsStatus) *
                            100
                          ).toFixed()}%`
                        : countSet.Rejected
                      : 0}
                  </Typography>
                </Button>
              </Typography>
            )}
          </Grid>
          <Grid
            item
            display="flex"
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            xs={12}
            md={3}
            lg={3}
            sx={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
              sx={{ alignItems: "center" }}
            >
              <Tooltip
                title="Refresh"
                placement="top"
                arrow
                classes={{
                  arrow: styleclasses.arrow,
                  tooltip: styleclasses.tooltip,
                }}
              >
                <Button className={styleclasses.refreshbtn} onClick={Refresh}>
                  <RefreshRoundedIcon />{" "}
                </Button>
              </Tooltip>

              {!["Supplier", "Sub Supplier"].includes(getRole()) ? (
                <UploadInvoiceNew />
              ) : (
                <Button
                  onClick={createinvoiceopen}
                  className={styleclasses.downbn}
                  variant="contained"
                >
                  New Invoice
                </Button>
              )}
              <Tooltip
                title="Filter by"
                placement="top"
                arrow
                classes={{
                  arrow: styleclasses.arrow,
                  tooltip: styleclasses.tooltip,
                }}
              >
                <IconButton onClick={openFilterHandle}>
                  <FilterListRoundedIcon className={styleclasses.filtercolor} />
                </IconButton>
              </Tooltip>
            </Stack>
            {value == 1 && invoiceModelFlag && (
              <InvoiceFilter
                openModal={invoiceModelFlag}
                dataparams={dataparams}
                closeModal={closeFilterHandler}
                filterHandler={filterHandler}
                role={getRole()}
                emailSender={getSenderEmail()}
              />
            )}
            {value == 2
              ? invoiceModelFlag && (
                  <ExceptionListFilter
                    openModal={invoiceModelFlag}
                    exceptionParams={exceptionParams}
                    closeModal={closeFilterHandler}
                    filterHandler={filterHandler1}
                  />
                )
              : ""}
            {value == 3
              ? invoiceModelFlag && (
                  <RejectedInvFilter
                    openModal={invoiceModelFlag}
                    rejectedParams={rejectedinvparams}
                    closeModal={closeFilterHandler}
                    filterHandler={filterHandler2}
                  />
                )
              : ""}
          </Grid>
        </Grid>
        <Grid sx={{ pt: 3, pl: 2, position: "relative" }} container spacing={2}>
          <Grid
            sx={{
              position: "absolute",
              width: "100%",
            }}
            item
            xs={12}
            md={12}
          >
            <TabContext value={value}>
              <TabPanel value="1" className={styleclasses.tabtaneldiv}>
                <InvoiceTable
                  invoiceDataList={Data}
                  loading={loading}
                  Refresh={Refresh}
                />
                <PagenationPages
                  dataparams={dataparams}
                  rowHandle={rowHandle}
                  totalRecord={totalrecords}
                  previorsPage={previorsPage}
                  nextPage={nextPage}
                />
                {/* <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      className={styleclasses.paginationrow}
                    >
                      <Typography>
                        Rows Per Page:
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={dataparams.count}
                            onChange={rowHandle}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      className={styleclasses.paginationitem}
                    >
                      <Typography className={styleclasses.paginationnum}>
                        {dataparams.offset * dataparams.count + 1}-
                        {totalrecords <
                        dataparams.count * (dataparams.offset + 1)
                          ? totalrecords
                          : dataparams.count * (dataparams.offset + 1)}{" "}
                        of {totalrecords} items
                        {dataparams.offset > 0 && (
                          <KeyboardArrowLeftRoundedIcon
                            onClick={previorsPage}
                            className={styleclasses.iconpaginationleft}
                          />
                        )}
                        {totalrecords >
                          dataparams.count * (dataparams.offset + 1) && (
                          <ChevronRightRoundedIcon
                            onClick={nextPage}
                            className={styleclasses.iconpaginationright}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid> */}
              </TabPanel>
              <TabPanel value="2" className={styleclasses.tabtaneldiv}>
                <ExceptionInvoiceTable
                  exceptionDataList={exceptionData}
                  loading={loading}
                />{" "}
                <PagenationPages
                  dataparams={exceptionParams}
                  rowHandle={rowHandle}
                  totalRecord={exceptionCount}
                  previorsPage={previorsPage}
                  nextPage={nextPage}
                />
                {/* <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      className={styleclasses.paginationrow}
                    >
                      <Typography>
                        Rows Per Page:
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={exceptionParams.count}
                            onChange={rowHandle}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      className={styleclasses.paginationitem}
                    >
                      <Typography className={styleclasses.paginationnum}>
                        {exceptionParams.offset * exceptionParams.count + 1}-
                        {exceptiontotalrecords <
                        exceptionParams.count * (exceptionParams.offset + 1)
                          ? exceptiontotalrecords
                          : exceptionParams.count *
                            (exceptionParams.offset + 1)}{" "}
                        of {exceptiontotalrecords} items
                        {exceptionParams.offset > 0 && (
                          <KeyboardArrowLeftRoundedIcon
                            onClick={previorsPage}
                            className={styleclasses.iconpaginationleft}
                          />
                        )}
                        {exceptiontotalrecords >
                          exceptionParams.count *
                            (exceptionParams.offset + 1) && (
                          <ChevronRightRoundedIcon
                            onClick={nextPage}
                            className={styleclasses.iconpaginationright}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid> */}
              </TabPanel>
              <TabPanel value="3" className={styleclasses.tabtaneldiv}>
                <RejectedInvoiceList
                  rejectedDataList={rejectedData}
                  loading={rejlistloading}
                  Refresh={Refresh}
                />
                <PagenationPages
                  dataparams={rejectedinvparams}
                  rowHandle={rowHandle}
                  totalRecord={rejlistcount}
                  previorsPage={previorsPage}
                  nextPage={nextPage}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
        <Uploadinvoice
          open={inviteSuppliermodal}
          handleToggle={createinvoiceopen}
          successCall={successCall}
        />
      </Grid>
    </>
  );
}
