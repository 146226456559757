import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const useStyles = makeStyles((theme) => ({
  tablerow: {
    background: "black !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
    color: "#14142A !important",
    fontWeight: 600,
  },
  iconpaginationleft: {
    margin: "0px 8px",
    cursor: "pointer",
    marginLeft: "20px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    cursor: "pointer",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
  pagebutton: {
    color: "#14142A !important",
    margin: "0px 16px !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 5%) !important",
    backgroundColor: "#FFFFFF !important",
    minWidth: "7ch !important",
    height: "48px !important",
    marginTop: "8px",
    borderRadius: "8px !important",
  },
  selectbutton: {
    border: "unset !important",
    height: "48px !important",
    "&:hover": {
      border: "unset !important",
    },
  },
  paginationitems: {
    color: "#4E4B66 !important",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
}));
function PagenationPages(props) {
  const styleclasses = useStyles();
  const { dataparams, rowHandle, totalRecord, previorsPage, nextPage } = props;
  return (
    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
      <Grid item xs={6} md={6} className={styleclasses.paginationrow}>
        <Typography className={styleclasses.paginationnum}>
          Rows Per Page:
          <FormControl className={styleclasses.pagebutton}>
            <Select
              value={dataparams.count}
              onChange={rowHandle}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={styleclasses.selectbutton}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} className={styleclasses.paginationitem}>
        <Typography className={styleclasses.paginationitems}>
          {dataparams.offset * dataparams.count + 1}-
          {totalRecord < dataparams.count * (dataparams.offset + 1)
            ? totalRecord
            : dataparams.count * (dataparams.offset + 1)}{" "}
          of {totalRecord} items
          {dataparams.offset > 0 && (
            <KeyboardArrowLeftRoundedIcon
              onClick={previorsPage}
              className={styleclasses.iconpaginationleft}
            />
          )}
          {totalRecord > dataparams.count * (dataparams.offset + 1) && (
            <ChevronRightRoundedIcon
              onClick={nextPage}
              className={styleclasses.iconpaginationright}
            />
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PagenationPages;
